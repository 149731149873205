import getData from "@/__main__/get-data.mjs";
import LolProbuildMatches from "@/game-lol/models/lol-probuild-matches.mjs";
import * as API from "@/game-lol/utils/api.mjs";

function fetchData(params) {
  const [proId] = params;

  if (!proId) return;

  return getData(
    API.getProMatchHistory({
      first: 20,
      playerIds: [proId],
    }),
    LolProbuildMatches,
    ["lol", "proBuildProMatchlist", proId],
    {
      shouldFetchIfPathExists: true,
    },
  );
}

export default fetchData;
